.tableBody {
  &.tableBody > tr {
    &,
    &:hover {
      > td {
        background-color: transparent;
      }
    }
  }
}

.contentRowTop {
  &.contentRowTop > td {
    border-top: 1px solid theme('colors.gray.light400');
    border-right: 0;
    border-bottom: 0;
    border-left: 0;

    &:first-child {
      border-left: 1px solid theme('colors.gray.light400');
    }

    &:last-child {
      border-right: 1px solid theme('colors.gray.light400');
    }
  }
}

.contentRowBottom {
  &.contentRowBottom > td {
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid theme('colors.gray.light400');
    border-left: 0;

    &:first-child {
      border-left: 1px solid theme('colors.gray.light400');
    }

    &:last-child {
      border-right: 1px solid theme('colors.gray.light400');
    }
  }
}

.spacerRow {
  &.spacerRow > td {
    padding: 0;
    &,
    &:first-child {
      border: 0;
    }
  }
}
