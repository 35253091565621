.sappi-ant-override-order-booking-search-results{
  .ant-spin-nested-loading {
    z-index: -1;
  }
  .ant-table {
    &.ant-table-empty .ant-table-tbody .ant-table-cell {
      border: none
    }
    table {
      border-spacing: 0 75px;
      margin-top: -75px;
      margin-bottom: -75px;
    }
  }
  tbody td {
    border-top: 1px solid theme('colors.gray.light400');
    border-bottom: 1px solid theme('colors.gray.light400');
    &:first-child {
      border-left: 1px solid theme('colors.gray.light400');
    }
  }
  &--hide-headers {
    .ant-table {
      border: 1px solid transparent; // encases our content so it doesn't spill over
      table {
        border-spacing: 0;
        margin-top: initial;
        margin-bottom: initial;
        thead th {
          display: none;
        }
      }
    }
  }
}