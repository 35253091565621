.quick-order-container {
  min-width: 1100px;
}
select.customer-select {
  @apply text-black-black;
  border-bottom: 1px solid;
  @apply border-gray-400;
  font-size: 21px;
  height: 50px;
  font-weight: 500;
}
.customer-select-label {
  font-size: 21px;
  font-weight: 300;
}