.quick-order-booking-table {
    div > table {
        > thead {
            tr > th {
                border-bottom: none;
            }
            tr:first-child {
                th {
                    @apply text-gray-gray;
                    text-align: left !important;
                    text-transform: uppercase;
                    border-right: 2px solid white;
                }
                th:last-child, th:first-child {
                    border-right-style: none;
                }
            }
        }
    }
    &.quick-order-booking-table--sheets {
        div > table {
            > thead {
                tr:last-child {
                    th:nth-child(4), th:nth-child(5) {
                        border-right: 2px solid white;
                    }
                }
            }
            > tbody {
                tr {
                    td:nth-child(5), td:nth-child(6) {
                        border-right: 1px solid theme('colors.gray.whiteSmoke600');
                    }
                }
            }
        }
    }
    &.quick-order-booking-table--reels {
        div > table {
            > thead {
                tr:last-child {
                    th:nth-child(4), th:nth-child(6) {
                        border-right: 2px solid white;
                    }
                }
            }
            > tbody {
                tr {
                    td:nth-child(5), td:nth-child(7) {
                        border-right: 1px solid theme('colors.gray.whiteSmoke600');
                    }
                }
            }
        }
    }
    &.quick-order-booking-table--release {
        div > table {
            > thead {
                tr:last-child {
                    th:nth-child(2), th:nth-child(4) {
                        border-right: 2px solid white;
                    }
                }
            }
            > tbody {
                tr {
                    td:nth-child(3), td:nth-child(5) {
                        border-right: 1px solid theme('colors.gray.whiteSmoke600');
                    }
                }
            }
        }
    }
}
.cell-min-width {
    min-width: 125px;
}