
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

@import './tailwind-base.scss';
// @tailwind components;
@tailwind utilities;


// @layer base {
//   h1 {
//     @apply text-5xl;
//   }
//   h2 {
//     @apply text-4xl;
//   }
//   h3 {
//     @apply text-3xl;
//   }
//   h4 {
//     @apply text-2xl;
//   }
//   h5 {
//     @apply text-xl;
//   }
//   h6 {
//     @apply text-base;
//   }
// }