.summary {
  max-height: 100px;
  overflow: hidden;
  position: relative;
  line-height: 28px;
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  }
}
