.filter-modal-container {

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 47px !important;
  }

  .filter-modal-input {
    width: 100%;
    height: 45px;
  }

  .filter-modal-footer {
    text-align: left;
    border-top: 0;
  }

  .filter-modal-body {
    background-color: theme('colors.white.white');
  }

  .filter-modal-border {
    background-color: theme('colors.gray.whiteSmoke200');
    padding: 20px;
  }
}
