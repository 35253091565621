.table {
  tbody td {
    border-bottom: 1px solid theme('colors.gray.light400');
    border-top: 1px solid theme('colors.gray.light400');
    vertical-align: top;
  }
}

.table--blue :global(.ant-table) {
  background: theme('colors.lavender.pale');
}
