.basketTable {
  thead > tr > th {
    text-align: center;
    padding: 14px;
  }

  tbody > tr > td:last-child {
    border-right: 0;
  }

  tbody > tr > td:first-child {
    border-left: 0;
  }

  tbody > tr > td {
   padding-top: 20px;
   vertical-align: top;
  }

  tfoot > tr > td:first-child {
    padding-right: 30px;
  }

  input::placeholder {
    color: theme('colors.blue.corporate');
  }
}
