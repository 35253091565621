.sappi-override-samples-basket-table {
  .ant-table-tbody .ant-table-row .ant-table-cell {
    vertical-align: top;
    padding: 15px;
  }
  .ant-table tfoot > tr > td {
    border-top: 1px solid theme('colors.gray.light400');
    border-bottom: 1px solid theme('colors.gray.light400');
    padding: 16px;
  }
}