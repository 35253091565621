.table{
  > thead tr th{
    font-weight: 700;
    font-size: theme('fontSize.lg');
    padding: 15px;
    border-bottom: 1px solid theme('colors.gray.light400');
    border-right: 1px solid theme('colors.gray.light400');
    text-align: left;
    height: 29px;
    &:last-child {
      border-right: none;
    }
  }
  > tbody tr td {
    vertical-align: top;
    font-size: theme('fontSize.lg');
    padding: 20px 15px;
    border-right: 1px solid theme('colors.gray.light400');
    &:last-child {
      border-right: none;
    }
  }
}