
.inputLabelWidth {
  min-width: 157px;
}

.orderCellWidth {
  min-width: 134px;
}

.searchIcon {
  color: theme('colors.blue.pacific') !important;
}

.sappiPopoverAvailableDocumentWidth {
  width: 1222px;
}

