.TopMenu {
  font-size: 17px;
  color:  theme('colors.gray.dark200');
}

.SappiLogo {
  &:hover {
    opacity: .6;
  }
}
