.available-documents .sappi-ant-override-table .ant-table thead th {
  height: 82px;
  padding: 0 0 0 5px;
}

.available-documents .sappi-ant-override-table .ant-table .ant-table-head .ant-table-cell {
  padding: 0;
  margin: 0;
}

.sappi-pagination-item-link  .ant-pagination-item-link {
  width: 32px;
}

.hide-icon .ant-table-cell {
  button {
    display: none;
  }
}

.available-documents .sappi-ant-override-table tbody {
  td:nth-last-child(2), td:last-child {
    border-right: none;
  }
}

.available-documents .ant-table-container table > thead > tr:first-child {
  th:last-child  {
    width: 40px;
  }
  th:nth-last-child(2) {
    border-right: none;
  }
}