.sappi-ant-override-popover-search {
  > .ant-popover-content > .ant-popover-arrow {
    border-right-color: theme('colors.gray.dark200');
    border-bottom-color: theme('colors.gray.dark200');
    border-left-color: theme('colors.gray.dark200');
    border-top-color: theme('colors.gray.dark200');
  }
}

.sappi-popover-search-width {
  width: 1325px;
}