.helpContent {
  img, svg, video, canvas, audio, iframe, embed, object {
    display: inline;
    width: auto !important;
    height: auto !important;
  }

  span {
    font-size: 16px ;
  }
}
