.sappi-ant-override-datepicker {
  &.ant-picker {
      @apply shadow-md;
      @apply border-none;
      @apply text-blue-pacific;
      box-sizing: border-box;
      padding: 5px;
      height: 34px;
      font-size: 15px;
  }

  .ant-picker-input > input{
    font-size: 16px;
    @apply text-blue-pacific;
  }
}

.sappi-ant-override-datepicker-error {
  &.ant-picker {
    @apply shadow-md;
    color: theme('colors.red.red');
    box-sizing: border-box;
    padding: 5px;
    height: 34px;
    font-size: 15px;
    box-shadow: 0 0 10px theme('colors.red.red');
  }

  .ant-picker-input > input{
    font-size: 16px;
    color: theme('colors.red.red');
  }

}

.sappi-ant-override-datepicker-required {
  &.ant-picker {
    border-bottom: 2px solid theme('colors.red.red');
  }
}

.sappi-ant-datepicker-tooltip {
  .ant-tooltip-inner {
    color: white !important;
    background-color: theme('colors.red.red');
  }
  .ant-tooltip-arrow-content {
    background-color: theme('colors.red.red');
  }
}


