.quantityInput {
  &:global(.error) {
    border: 2px solid theme('colors.red.red');
    box-shadow: 0 0 10px theme('colors.red.red');
  }
}

.coverFormatPrintedDisplay {
  width: 100%;
  height: 150px;
  background-size: cover;
}