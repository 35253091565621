.table {
  :global(.cell-width-15) {
    width: 15%;
  }

  :global(.cell-width-40) {
    width: 40%;
  }

  :global(.total-row) > td {
    padding-top: 18px;
    padding-bottom: 36px;
    border-color: theme('colors.gray.light400');

    &,
    &:hover {
      background-color: theme('colors.white.white');
    }
  }

  tr:global(.stripe) > td {
    background-color: theme('colors.lavender.pale');
  }
}
