.basketTable {
  border-bottom: 1px solid theme('colors.blue.cornflower');
  &:global(.sappi-ant-override-table.table-striped) tbody tr {
    background-color: initial;
  }
  &:global(.sappi-ant-override-table) tbody td {
    border: none;
    padding-top: 25px;
    padding-bottom: 25px;
  }
   
}
