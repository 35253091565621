.imageWidth {
    width: calc(100% + 20px);
    min-width: 64px;
    max-width: 76px;
}

.imageContainer {
    vertical-align: top;
    float: left;
    width: 31%;
    box-sizing: border-box;
    padding-right: 15px;
}
