.label {
  width: 400px;
  justify-content: flex-end;
}

.widgetLabel {
  min-width: 183px;
  width: 267px;
  justify-content: flex-end;
}

.widgetInput {
  min-width: 267px;
  max-width: 432px;
}

