.table {
  thead th {
    background-color: theme('colors.white.white');
    color: theme('colors.black.black');
    border-bottom: 1px solid theme('colors.gray.light400');
    &:last-child {
      border-left: 1px solid theme('colors.gray.light400');
    }
  }
  tbody  {
    tr td {
      vertical-align: baseline;
      border-bottom: 1px solid theme('colors.gray.light400');
    }
    td:first-child {
      border-left: none;
    }
    td:last-child {
      border-right: none;
    }
  }
}