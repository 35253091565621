@import '~antd/lib/style/themes/index.less';
@import '~antd/lib/style/mixins/index.less';
@import '~antd/lib/style/core/base.less';
// @import '~antd/lib/style/core/global.less';
@import '~antd/lib/style/core/iconfont.less';
@import '~antd/lib/style/core/motion.less';
@import '~antd/lib/style/components.less';

.ant-popover-inner-content {
  padding: 0; // required for custom end to end popovers
}

.popover-remove-arrow {
  > .ant-popover-content > .ant-popover-arrow {
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}

.sappi-collapse-custom-collapse {
  background-color: theme('colors.blue.pacific');
  .sappi-collapse-custom-summary-collapse {
    border-color: theme('colors.white.white');
    .ant-collapse-header {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 50px;
      font-size: 16px;
      color: theme('colors.white.white');;
    }
    .ant-collapse-content {
      color: theme('colors.gray.dark200');;
      background-color: theme('colors.white.white');;
    }
  }
}

.basic-table table > thead > tr > th {
  font-weight: 700;
  font-size: 16px;
  color: theme('colors.gray.whiteSmoke400');
}

.basic-table  table > tbody > tr > td.ant-table-cell {
  padding: 20px 14px;
  vertical-align: top;
}

.basic-table .table-striped table > tbody > tr:nth-child(even):hover > td {
  background: theme('colors.blue.cornflowerPale');
}

@primary-color:#0090D9;
@table-header-sort-bg:#54585a;
@table-header-bg:#54585a;
@table-padding-vertical:10px;
@table-padding-horizontal:10px;
@table-header-color:#fff;
@btn-disable-bg:#73d0ff;
@btn-disable-color:#fff;
@btn-border-radius-base:0px;
@btn-padding-horizontal-base:20px;
@btn-height-base:20px;
@btn-default-border:#4a4a4a;
@card-head-background:#0090D9;
@card-head-color:#fff;
@card-background:#f1f1f1;
@card-head-padding:10px;
@card-head-height:46px;
@divider-color:#ccc;
@tabs-card-tab-active-border-top:2px solid blue;
@tabs-card-horizontal-padding:12px 40px;
@tabs-title-font-size:16px;
@tabs-vertical-margin:0;