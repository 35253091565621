.table {
  tbody {
    tr:last-child td {
      border-bottom: 1px solid theme('colors.gray.light400');
    }
    td:first-child {
      border-left: none;
    }
    td:last-child {
      border-right: none;
    }
  }
}
