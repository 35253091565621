.item-detail-table {
  max-width: 140px;

  tbody tr td {
    border: 0;
  }

  :global(.table-striped) & tbody tr:nth-child(2n) {
    background-color: transparent;
  }
}
