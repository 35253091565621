.excelImageSize {
  min-width: 31px;
  height: 29px;
}

.sappi-ant-override-claims-reporting-results {
  thead th {
    &:last-child {
      border-right: 1px solid theme('colors.gray.dark500');
    }
  }
  tbody td {
    padding-top: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid theme('colors.gray.light400');
  }
}

.sappi-ant-override-file-claim {
  thead th {
    border-right: 1px solid theme('colors.gray.light400');
    &:last-child {
      border-right: 1px solid theme('colors.gray.dark500');
    }
  }
  tbody td {
    padding-top: 20px;
    padding-bottom: 30px;
    vertical-align: top;
    border-bottom: 1px solid theme('colors.gray.light400');
    border-right: 1px solid theme('colors.gray.light400');
  }
}