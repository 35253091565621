.table {
  tbody {
    td:first-child {
      border-left: none;
    }
    td {
      border-right: none;
    }
  }
}
