.detailsPanel {
  &,
  &.detailsPanel {
    > :global(.ant-collapse-content) > :global(.ant-collapse-content-box) {
      padding: 0;
    }
  }
}

.detailsTable {
  :global(.ant-table-thead) > tr > th {
    background-color: theme('colors.blue.cornflower');
  }
}
