.max-width-200 {
  max-width: 200px
}

.max-width-87 {
  max-width: 87px
}

.min-width-100 {
  min-width: 100px
}
