.sappi-ant-tooltip-error {
  .ant-tooltip-inner {
    color: theme('colors.white.white')!important;
    background-color: theme('colors.red.red');
    width: max-content;
  }
  .ant-tooltip-arrow-content {
    background-color: theme('colors.red.red');
  }
}
