.label-colon::after {
  content: ':';
}

.order-details table > tbody > tr > td {
  vertical-align: top;
}

.delivery-table .ant-table-thead {
  display: none;
}

.delivery-table  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset;
}

.order-history {
  border: 0;
}

.order-history .table-row-expand-icon {
  color: #0090D9 !important;
  margin-top: 4px;
  margin-left: -8px;
}

.order-history .ant-collapse-header {
  background: #BFE2F5;
  min-height: 60px;
  border: 0;
}

.order-history .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.order-history  .sappi-ant-override-table thead th {
  border-right: 0;
}

.order-history .sappi-ant-override-table tbody td {
  border-right: 0;
}