.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.wrapper {
  box-sizing: border-box;

  margin: 0 128px;

  @media print {
    margin: 0 10px;
  }

  &:after {
    visibility: hidden;
    display: block;
    content: '';
    clear: both;
    height: 0;
  }
}

.pre-footer-container {
  min-height: calc(100vh - 100px);
}

.app-link {
  color: theme('colors.blue.pacific');
  text-decoration: underline;
}

.sappi-notification {
  width: 100vw;
  background: theme('colors.yellow.yellow');
  text-align: center;
  padding-bottom: 20px;
}

// TODO: remove after Sappi Header is redone
.react-icon-nav__item {
  display: inline-flex;
  white-space: nowrap;
  border-right: solid 1px theme('colors.gray.light400');
  font-size: 24px;
  padding: 0 10px;
  color: theme('colors.gray.gray');
  line-height: 28px;
  height: 28px;
  margin-top: 5px;

  &:hover {
    color: theme('colors.gray.grayHover');
  }
}
