.sappi-ant-override-order-booking-alternate-sizes-results{
  tbody td {
    padding-top: 20px;
    padding-bottom: 30px;
    border-top: 1px solid theme('colors.gray.light400');
    border-bottom: 1px solid theme('colors.gray.light400');
    &:first-child {
      border-left: 1px solid theme('colors.gray.light400');
    }
}
}