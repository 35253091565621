.invoice-details table > thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    color: theme('colors.gray.whiteSmoke400');
}

.invoice-details table > tbody > tr > td.ant-table-cell {
    padding: 20px 14px;
    vertical-align: top;
}

.invoice-details.table-striped table > tbody > tr:nth-child(even):hover > td {
    background: theme('colors.blue.cornflowerPale');
}
