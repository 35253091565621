.lander {
  width: 100%;
  background-color: theme('colors.white.white');
  min-height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}

.landerTitle {
  font-size: 26px;
  color: theme('colors.blue.iris');
  margin-bottom: 20px;
}

.landerBody {
  font-size: 14px;
  color: theme('colors.gray.dim300');
  line-height: 22px;
}

.landerContentBlock {
  background-color: theme('colors.white.white');
  width: 500px;
  padding: 20px 130px 80px 20px;
  margin: 30px;
  box-sizing: border-box;
}
