.resultsTable {
  &.resultsTable {
    border-collapse: collapse;
  }

  :global(.ant-table-thead) > tr > th {
    background-clip: padding-box;
  }

  thead::after {
    content: '';
    display: block;
    height: 9px;
  }

  tbody td {
    border: 1px solid theme('colors.gray.light400');
  }
}

.firstColCell {
  width: 13%;
}

.rowGroupEven {
  :global(.bkgd-element) {
    background-color: theme('colors.lavender.pale');
    background-clip: padding-box;
  }
}

.basketCell {
  &.basketCell {
    border-left: 0;
    border-right: 0;
    padding: 0;
  }
}

.basketCellInner {
  padding: 10px;
}

.noResultsCell {
  &.noResultsCell {
    border: 0;
  }
}
