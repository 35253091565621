.sappi-ant-override-steps {
  margin-top: 35px;
  margin-right: 38px;

  .ant-steps-label-vertical .ant-steps-item-content {
    position: absolute;
    top: -115%;
    width: 156px;
    margin-left: -20px
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: theme('colors.blue.denim');
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: theme('colors.blue.denim');
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: theme('colors.blue.denim');
    border-color: theme('colors.blue.denim');
  }

  .ant-steps-item-active {
    .ant-steps-item-subtitle {
      color: theme('colors.blue.denim');
    }
  }
}