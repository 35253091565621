.sappi-ant-collapse-override {
  &.ant-collapse > .ant-collapse-item {
    > .ant-collapse-header {
      background-color: theme('colors.gray.dark200');
      color: theme('colors.white.white');
      padding-left: 43px;
    }
  }
}

.sappi-product-availability-table-override {
  .ant-table-thead > tr > th {
    background-color: theme('colors.gray.light100');
    color: theme('colors.gray.dark200');
    &:last-child {
      border-right: none;
    }
  }
  tbody tr:first-child td:last-child {
    border-right: none;
  }
}
