@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

.dropdown-icon {
    &:after {
        @extend %fa-icon;
        @extend .fas;
        text-decoration: inherit;
        padding-left: 10px;
        font-size: 1.5rem;
        line-height: 16px;
        text-align: right;
        right: 8px;
        top: -3%;
        pointer-events: none;
        position: absolute;
        height: 0px;
        overflow: visible;
        content: fa-content($fa-var-caret-down);
        color: black;
    }
    &.dropdown-icon--disabled {
        &:after {
            color: gray;
        }
    }
}