.table :global(.ant-table) {
  tbody tr {
    td {
      border-left: none;
      border-bottom: 1px solid theme('colors.gray.light400');
    }
    td:last-child {
      border-right: none;
    }
    &:first-child {
      td:first-child {
        border-left: 1px solid theme('colors.gray.light400');
      }
    }
  }
}
