.utilizationViewTable {
  &:global(.sappi-ant-override-table.table-striped) tbody tr {
    background-color: initial;
    cursor: pointer;
  }
  &:global(.sappi-ant-override-table) tbody td {
    border: none;
  }
}
.selectedRow {
  background-color: theme('colors.gray.whiteSmoke400') !important;
}