.sappi-ant-override-table {
  .sappi-toggle-show-all-button {
    color: theme('colors.blue.pacific');
  }

  .ant-table {
    color: inherit;
  }

  thead {
    tr > th {
      border-right: 1px solid white;
      font-weight: 700;
      font-size: 16px;

      &:last-child {
        border-right: 0;
      }
    }
  }
  tbody {
    td {
      border-right: 1px solid theme('colors.gray.light400');
    }
    td:first-child {
      border-left: 1px solid theme('colors.gray.light400');
    }
  }
  &.no-outside-borders {
    tbody {
      td:last-child {
        border-right: 0;
      }
    }
  }
  &.table-striped {
    tbody {
      tr:nth-child(even) {
        background: theme('colors.lavender.pale');
      }
      tr.ant-table-row-level-1:nth-child(even) {
        background: theme('colors.lavender.pale');
      }
    }
  }

  .table-row-expand-icon {
    text-decoration: none !important;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    float: left;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    padding: 0;
    line-height: 17px;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
  }
}
