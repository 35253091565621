.coverPaperWidth {
  max-width: 25%;
}

.coverPaperImageSize {
  min-width: 125px;
  height: 85px;
}

.inputBackground{
  background-color: theme('colors.white.white');
}