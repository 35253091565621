.basketPopUp {
  width: 430px;
  max-height: 600px;
}

.basketPopUpItemContainer {
  max-height: 450px;
  overflow: auto;
}

.basketPopUpFuzz {
  height: 50px;
  @apply bg-gradient-to-t;
  @apply from-white-white;
  bottom: 0;
  position: absolute;
  width: 100%;
}