.button {
  margin: 0;
  padding: 10px 20px;
  white-space: nowrap;
  max-width: 150px;
}

.buttonIcon {
  margin-right: 8px;
  font-size: 2rem;
  vertical-align: middle;
}

.buttonText {
  display: inline-block;
  position: relative;
  text-align: left;
  font-size: 1.15rem;
  font-weight: 500;
  vertical-align: middle;
  white-space: normal;
}
