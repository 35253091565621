.sku-lookup-modal-container {
  .ant-modal-content {
    padding: 10px;
  }

  .ant-modal-header{
    padding: 0;
  }

  .filter-modal-input {
    width: 100%;
    height: 45px;
  }

  .filter-modal-footer {
    text-align: left;
    border-top: 0;
  }

  .filter-modal-body {
    background-color: theme('colors.white.white');
    padding-bottom: 0;
    padding-top: 10px;
  }

  .filter-modal-border {
    background-color: theme('colors.gray.whiteSmoke200');
    padding: 20px;
  }
}
