.table{
  > thead tr td{
    font-weight: 700;
    font-size: 1.143rem;
    border-bottom: 1px solid theme('colors.blue.pacific');
    padding: 15px;
  }
  > tbody tr td {
    font-size: 1.143rem;
    padding: 0 15px;
  }
  > tbody tr:first-child td {
    padding-top: 15px;
  }
}