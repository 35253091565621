.sappi-ant-override-tabs {
  .ant-tabs-card > .ant-tabs-nav {
    &:before {
      border-bottom: 1px solid theme('colors.gray.light400');
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid theme('colors.gray.light400');
    margin-right: 0 !important;
    border-radius: 0 !important;
    font-size: 18px;
    color: theme('colors.gray.dark500');
    background-color: theme('colors.gray.whiteSmoke400');

    &.ant-tabs-tab-disabled {
      color: theme('colors.gray.gray');
    }

    &.ant-tabs-tab-active {
      border-bottom-color: theme('colors.white.white');
      background-color: theme('colors.white.white');

      .ant-tabs-tab-btn {
        font-weight: 800;
        color: theme('colors.gray.dark500');
      }
    }
  }
  &.sappi-ant-override-tabs--gray
    .ant-tabs-card
    .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 4px solid theme('colors.gray.light400');
  }
  &.sappi-ant-override-tabs--lime
    .ant-tabs-card
    .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 4px solid theme('colors.green.citrus');
  }
  &.sappi-ant-override-tabs--green
    .ant-tabs-card
    .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 4px solid theme('colors.green.pineTree');
  }
  &.sappi-ant-override-tabs--blue
    .ant-tabs-card
    .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 4px solid theme('colors.blue.cobaltVariation');
  }
  &.sappi-ant-override-tabs--malibu
    .ant-tabs-card
    .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 4px solid theme('colors.blue.malibu');
  }

  .ant-tabs-tabpane {
    @apply border-l;
    @apply border-r;
    @apply border-b;
    @apply border-gray-light200;
    @apply p-4;
    @apply -mt-5;
    color: theme('colors.gray.dark200');
  }
}
