.sappi-ant-override-modal {
  font-family: 'Open Sans';
  .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 10px;
  }

  .ant-modal-header {
    @apply p-2;
  }
  .ant-modal-title {
    @apply text-white-white; 
    @apply px-5; 
    @apply py-3; 
    @apply text-xl; 
    @apply justify-between; 
    @apply items-center; 
    @apply bg-blue-pacific;
    @apply flex;
    font-weight: bold;
  }

  .ant-modal-footer {
    border-top: 0;
  }

  .ant-modal-body {
    padding: 10px;
  }

  .ant-modal-close-x {
    color: white;
  }
}