.view-sample-orders-table {
  &,
  .ant-table {
    table {
      border-spacing: 0 10px;
    }

    tr,
    tr:hover {
      td {
        padding: 20px 14px;
        border: 1px solid theme('colors.gray.light400');
        vertical-align: top;

        &:not(:last-child) {
          border-right: 0;
        }

        background-color: theme('colors.white.white');

        &:first-child {
          background-color: theme('colors.gray.whiteSmoke500');
        }

        &.order-cell {
          width: 27%;
        }

        &.status-cell {
          width: 8%;
        }

        &.product-cell {
          width: 12%;
        }

        &.qty-value-cell {
          width: 23%;
        }

        &.ship-to-cell {
          width: 12%;
        }

        &.actions-cell {
          width: 18%;
        }
      }

      &.subitem-row {
        td {
          background-color: theme('colors.gray.whiteSmoke500');
        }
      }
    }
  }
}
