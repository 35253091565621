.sappi-table-override-dashboard {
  thead tr > th {
    height: 60px;
    &:last-child {
      border-right: 1px solid theme('colors.gray.dark500');
    }
  }
  tbody tr > td {
    height: 100px;
  }
}
