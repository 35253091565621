.button {
  line-height: 25px;
  float: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  outline: none;
  background-color: theme('colors.gray.dark300');
  color: theme('colors.white.white');
  border: solid 1px theme('colors.gray.dark300');
  min-height: 20px;
  vertical-align: top;

  &:disabled {
    background-color: theme('colors.gray.dark300Disabled');
    border: solid 1px theme('colors.gray.dark300Disabled');
  }

  &:hover:not([disabled]) {
    background-color: theme('colors.gray.dark300Hover');
    border: solid 1px theme('colors.gray.dark300Hover');
  }

  &:focus {
    outline: 0;
  }
}

.buttonLink {
  color: theme('colors.blue.pacific');
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &:disabled {
    color: theme('colors.gray.light500');
    pointer-events: none;
  }
  &:focus {
    outline: 0;
  }
}

.button--primary {
  background-color: theme('colors.blue.pacific');
  border: solid 1px theme('colors.blue.pacific');
  color: theme('colors.white.white');

  &:disabled {
    background-color: theme('colors.blue.pacificDisabled');
    border: solid 1px theme('colors.blue.pacificDisabled');
  }

  &:hover:not([disabled]) {
    background-color: theme('colors.blue.pacificHover');
    border: solid 1px theme('colors.blue.pacificHover');
  }
}

.button--danger {
  background-color: theme('colors.red.venetian');
  border: solid 1px theme('colors.red.venetian');
  color: theme('colors.white.white');

  &:disabled {
    background-color: theme('colors.red.venetianDisabled');
    border: solid 1px theme('colors.red.venetianDisabled');
  }

  &:hover:not([disabled]) {
    background-color: theme('colors.red.venetianHover');
    border: solid 1px theme('colors.red.venetianHover');
  }
}

.button--inverse {
  color: theme('colors.blue.pacific');
  background-color: theme('colors.white.white');
  border: solid 1px theme('colors.gray.gray');

  &:disabled {
    color: theme('colors.blue.pacificDisabled');
    background-color: theme('colors.white.white');
  }

  &:hover:not([disabled]) {
    background-color: theme('colors.white.hover');
  }
}

.button--clear {
  background: transparent;
  color: theme('colors.blue.pacific');
  border: none;

  &:disabled {
    background-color: transparent;
    color: theme('colors.blue.pacificDisabled');
    border: none;
  }

  &:hover:not([disabled]) {
    background-color: transparent;
    color: theme('colors.blue.pacificHover');
    border: none;
  }
}

.button--round {
  border-radius: theme('borderRadius.DEFAULT');
}

.button--circle-danger {
  border-radius: 100px;
  padding: 1px 8px;
  background-color: theme('colors.red.red');
}

.button--tiny {
  padding: 0 5px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}

.button--primary-tiny {
  padding: 0 5px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  background-color: theme('colors.blue.pacific');
  border: solid 1px theme('colors.blue.pacific');
  color: theme('colors.white.white');

  &:disabled {
    background-color: theme('colors.blue.pacificDisabled');
    border: solid 1px theme('colors.blue.pacificDisabled');
  }

  &:hover:not([disabled]) {
    background-color: theme('colors.blue.pacificHover');
    border: solid 1px theme('colors.blue.pacificHover');
  }
}
